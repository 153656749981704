import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { PikAuthService } from 'pik-product-header';
import { ConfigService } from '../services/config.service';


@Injectable()
export class ApplyTokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: PikAuthService,
    private $config: ConfigService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes(this.$config.config.api)) {
      return next.handle(req);
    }

    const authReq = req.clone({
      headers: req.headers.set('Authorization', this.authService.getAuthorizationHeaderValue())
    });

    return next.handle(authReq);
  }
}
