import { Observable, of, EMPTY } from 'rxjs';
import { map, shareReplay, finalize, catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { FetcherService, Response } from './fetcher.service';

export interface SlugRights {
  create: boolean,
  edit: boolean,
  delete: boolean
}

export interface SectionRights {
  rightName: string;
  rightId: number;
}

export interface MenuItem {
  id: number;
  parentId: number | null;
  parentName: string | null;
  name: string;
  code: string;
  isVisible: boolean;
  isGS: boolean;
  order: number;
  systemName?: string;
  rights?: SectionRights[];
  resolvedRights?: SlugRights;
}

export interface MenuTreeItem {
  section: MenuItem;
  items: MenuItem[];
  isOpen: boolean;
}

export interface RootTreeItem {
  systemName: string;
  name: string;
  tree: MenuTreeItem[];
  isOpen: boolean;
}

export interface MenuSystemItem {
  systemName: string;
  prettyName: string;
  menu: MenuItem[];
}

@Injectable()
export class NewMenuService {
  items: MenuItem[] = [];
  menuThree: RootTreeItem[];
  activeRequest: Observable<any>;

  constructor(
    private $fetcher: FetcherService,
    private $router: Router
  ) { }

  getTree(): Observable<RootTreeItem[]> {
    if (this.menuThree) {
      return of(this.menuThree);
    }
    if (this.activeRequest) {
      return this.activeRequest;
    }
    return this.activeRequest = this.$fetcher.get<Response<MenuSystemItem[]>>('/api/v1/menu', {}, '')
      .pipe(
        catchError((err) => {
          if (err.status === 500) {
            this.menuThree = [];
            delete this.activeRequest;
            this.$router.navigate(['error-backend']);
          }
          return EMPTY;
        }),
        map(({ data }) => {
          this.menuThree = [];
          if (!data || !data.reduce((p, c) => p || (c && c.menu && c.menu.length > 0), false)) {
            return [];
          }
          data.forEach(system => {
            if (system.menu && system.menu.length) {
              const tree: MenuTreeItem[] = [];
              const hashes = new Map<number, number>();
              system.menu
                .sort((a, b) => a.parentId * 1 - b.parentId * 1)
                .forEach(item => {
                  if (!item.isVisible) { return; }
                  if (item.parentId && hashes.has(item.parentId)) {
                    tree[hashes.get(item.parentId)].items.push(item);
                  } else {
                    const menuTreeItem: MenuTreeItem = {
                      section: item,
                      items: [],
                      isOpen: false
                    };
                    hashes.set(item.id, tree.push(menuTreeItem) - 1);
                  }
                  item.systemName = system.systemName;
                  item.resolvedRights = this.generateRights(item.rights);
                  this.items.push(item);
                });
              tree
                .sort((a, b) => a.section.order - b.section.order)
                .forEach(section => section.items.sort((a, b) => a.order - b.order));
              this.menuThree.push({
                systemName: system.systemName,
                name: system.prettyName,
                tree,
                isOpen: false
              });
            }
          });

          this.items.sort((a, b) => {
            return a.order - b.order;
          });
          return this.menuThree;
        }),
        finalize(() => {
          delete this.activeRequest;
        }),
        shareReplay()
      );
  }

  getIsGS(code: string): Observable<boolean> {
    return this.getTree().pipe(
      map(resolve => {
        return this.items.some(item => item.code.toLowerCase() === code && item.isGS);
      }
      ));
  }

  getRights(slug: string, systemName): SlugRights {
    const menuItem = this.items.filter(item => {
      return item.code.toLowerCase() === slug && item.systemName === systemName
    })[0];
    return menuItem ? menuItem.resolvedRights : { edit: false, create: false, delete: false };
  }

  hasAccess(): Observable<boolean> {
    return this.getTree().pipe(
      map(resolve => {
        return resolve && resolve.reduce((p, c) => {
          return p || (c && c.tree.length > 0);
        }, false);
      }
    ));
  }


  private generateRights(rights: SectionRights[]): SlugRights {
    const userRights: SlugRights = {
      create: false,
      edit: false,
      delete: false
    };

    rights.forEach(right => {
      if (right.rightId === 1) {
        for (const key in userRights) {
          if (userRights.hasOwnProperty(key)) {
            userRights[key] = true;
          }
        }
      } else if (right.rightId === 3) {
        userRights.edit = true;
      } else if (right.rightId === 4) {
        userRights.create = true;
      } else if (right.rightId === 5) {
        userRights.delete = true;
      }
    });

    return userRights;
  }
}
