import {
  Component, Input, Attribute,
  forwardRef, ChangeDetectorRef,
  ViewChild, OnInit, Output, EventEmitter,
  HostBinding, OnChanges, AfterViewInit, SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, Subject, concat, empty } from 'rxjs';
import { FetcherService } from '@app/core/services/fetcher.service';
import { map, debounceTime, distinctUntilChanged, tap, switchMap, filter, finalize, catchError } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NotificationsService } from '@app/core/services/notifications.service';

export enum KEY_CODE {
  ENTER = 13,
}

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true
    }
  ]
})
export class CustomSelectComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
  @HostBinding('class.multiple') @Input() multiple = false;

  public get value() {
    return this._value;
  }

  public set value(v) {
    this._value = v;
    this.onChange(this._value);
    this.onTouched();
  }

  private _value: any;
  isLoading = false;

  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() required = false;
  @Input() inline = false;
  @Input() search: string[];
  @Input() sortBy: string;
  @Input() sticky = false;
  @Input() filter: any;
  @Input() items: any[] | string;
  @Input() columns: string[];
  @Input() extra: any[] | string;
  @Input() modelExtraFields: any;
  @Input() key = 'name';
  @Input() appendTo = null;
  @Input() idKey;
  @Input() itemsApiPrefix = '/backoffice/v2';
  @Input() itemsSystemName = 'bop';

  collection$: Observable<any[]>;
  input$ = new Subject<string>();

  @ViewChild('selectEl') input: NgSelectComponent;
  @Output() blur = new EventEmitter();
  @Output() change = new EventEmitter();

  constructor(
    @Attribute('class') public classes: string,
    @Attribute('tabindex') public tabIndex: string,
    @Attribute('autofocus') private autoFocus: any,
    private _cd: ChangeDetectorRef,
    private $fetcher: FetcherService,
    private $notifications: NotificationsService
  ) { }

  ngOnInit() {
    // this.collection$ = this.initCollection();
  }

  ngAfterViewInit() {
    if (this.inline) {
      this.input.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter) {
      this.collection$ = this.initCollection();
    }
  }

  initCollection(): Observable<any[]> {
    if (typeof (this.items) === 'string') {
      const params = {};
      if (this.sortBy) {
        params['sortType'] = true;
        params['sortBy'] = this.sortBy;
      }
      if (this.filter) {
        Object.keys(this.filter).forEach((key) => {
          params[key] = this.filter[key];
        });
      }
      if (this.columns) {
        params['columns'] = this.columns.join(',');
      }
      if (this.search) {
        const initialArr = [];
        if (!this.required && this.inline && typeof(this._value) !== 'undefined' && this._value !== null) {
          initialArr.push({ id: null, [this.key]: 'Очистить' });
        }
        return concat(
          of(initialArr),
          this.input$.pipe(
            debounceTime(200),
            filter((term: string) => term && term.length > 2),
            distinctUntilChanged(),
            tap(() => this.isLoading = true),
            switchMap(
              (term: string) => {
                return this.$fetcher.autoCompleteBySlug<any[]>(`${this.items}`, {
                  column: this.search.join(','),
                  value: [term],
                  ...params
                }, this.itemsApiPrefix, this.itemsSystemName).pipe(
                  catchError((res) => {
                    if (res.error) {
                      this.$notifications.showError(
                        res.error.Status || res.error.status,
                        'Не удалось получить данные для автокомплита по ' + this.items,
                        5000
                      );
                    }
                    return of([]);
                  })
                );
              }
            ),
            tap(() => this.isLoading = false)
          )
        );
      } else {
        return this.$fetcher.getBySlug(this.items, { limit: 0, ...params }, this.itemsApiPrefix, this.itemsSystemName)
          .pipe(
            map((resolve) => resolve.data),
            catchError((res) => {
              if (res.error) {
                this.$notifications.showError(
                  res.error.Status || res.error.status,
                  'Не удалось получить данные для селекта по ' + this.items,
                  5000
                );
              }
              return of([]);
            })
        );
      }
    } else {
      return of(this.items);
    }
  }

  writeValue(value: string): void {
    this._value = value;
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.input.setDisabledState(isDisabled);
    this._cd.markForCheck();
  }

  onChangeValue(value) {
    if (this.multiple) { this.onBlur(); }
    this.change.emit(value);
  }

  onChange: any = (v) => {
    this.change.emit(v);
  };

  onTouched: any = () => { };

  onBlur() {
    this.input$.next();
    this.blur.emit();
  }

  selectAll() {
    // this.value = this.collection$.
  }

  compareWith(a, b) {
    return a.id === b.id;
  }

  clear() {
    this.value = '';
    this._cd.markForCheck();
  }
}
