import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RootTreeItem, NewMenuService } from '../services/menu.service';
import { map, finalize } from 'rxjs/operators';

@Injectable()
export class MenuResolver implements Resolve<RootTreeItem[]> {
  constructor(
    private $menu: NewMenuService,
    private $router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.$menu.getTree().pipe(
      finalize(() => {
        const path = route.children[0].url[0].path;
        if (path.includes('error') && this.$menu.items.length > 0) {
          this.$router.navigate(['resolve']);
          // this.$router.navigate(['error-access']);
          // if (this.$menu.items.length === 0 ) {
          // }
        }
      })
    );
  }
}
