import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationComponent } from '../../shared/components/notification/notification.component';

@Injectable()
export class NotificationsService {
  private activeSnacks: number[] = [];
  constructor(private $snackBar: MatSnackBar) { }

  showError(errorCode: number, errorMessage: string, duration?: number) {
    this.$snackBar.openFromComponent(NotificationComponent, {
      panelClass: ['mat-snack-bar-red', 'mat-snack-bar-custom'],
      duration: typeof(duration) === 'undefined' ? 3000 : duration,
      data: {
        type: 'error_outline',
        caption: `Код ошибки: ${errorCode}. ${errorMessage}`
      }
    });
  }

  showLoader(message: string, duration?: number): MatSnackBarRef<NotificationComponent> {
    return this.$snackBar.openFromComponent(NotificationComponent, {
      panelClass: ['mat-snack-bar-white', 'mat-snack-bar-custom'],
      duration: typeof(duration) === 'undefined' ? null : duration,
      data: {
        type: 'error_outline',
        caption: message
      }
    });
  }

  showMessage(message: string, type = 'done', duration?: number): MatSnackBarRef<NotificationComponent> {
    return this.$snackBar.openFromComponent(NotificationComponent, {
      panelClass: ['mat-snack-bar-green', 'mat-snack-bar-custom'],
      duration: typeof(duration) === 'undefined' ? 3000 : duration,
      data: {
        type,
        caption: message
      }
    });
  }
}
