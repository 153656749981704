export const environment = {
  production: false,
  appName: 'bopadmin',
  level: 'test',
  currentUser: 'main/isbi2',
  api: 'https://backoffice-api.test.saas.pik-digital.ru',
  assetsUrl: '',
  mdsUrl: 'https://mds-api.pik.ru',
  version: require('../../package.json').version,
  needAuth: true,
  homeUrl: '',
  homeApiUrl: '',
  needConfig: true,
  auth: {
    client_id: 'gateway_spa',
    scope: 'openid profile email offline_access gateway_api bop_api authentication_api bank_api',
    stsUrl: 'https://auth.test.saas.pik-digital.ru',
    allowExternal: true
  },
  GAID: '',
  headerVisualSettings: {
    hideSearch: true,
    hideUser: false,
    hidePersonalLink: true,
    hidePasswordChange: true,
    externalLogoSrc: ''
  }
}
