import { Directive, Input, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appInputFilter]',
})
export class InputFilterDirective implements OnInit {
  @Input() appInputFilter;
  regExp: RegExp;

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (this.regExp && (
      ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter'].indexOf(e.code) !== -1 ||
      (e.code === 'KeyA' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.code === 'KeyC' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.code === 'KeyV' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.code === 'KeyX' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.code === 'KeyA' && e.metaKey === true) || // Cmd+A (Mac)
      (e.code === 'KeyC' && e.metaKey === true) || // Cmd+C (Mac)
      (e.code === 'KeyV' && e.metaKey === true) || // Cmd+V (Mac)
      (e.code === 'KeyX' && e.metaKey === true) || // Cmd+X (Mac)
      e.code === 'ArrowRight' ||
      e.code === 'ArrowLeft' ||
      e.code === 'Home' ||
      e.code === 'End'
    )) {
      return;
    }

    if (this.regExp && !this.regExp.test(e.key)) {
      e.preventDefault();
    }
  }

  constructor() { }

  ngOnInit() {
    this.regExp = this.appInputFilter ? new RegExp(this.appInputFilter) : null;
  }
}
