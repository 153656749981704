import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `
    <div class="error">
      <div class="container error__container">
        <span class="error__number">404</span>
        <p class="error__message">
          Для данного справочника ещё не создан файл конфигурации. Воспользуйтесь навигацией слева, чтобы перейти в другой.
        </p>
      </div>
    </div>
  `,
  styleUrls: ['./undefined-table.component.scss']
})
export class UndefinedTableComponent { }
