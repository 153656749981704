import { AppComponent } from './app.component';
import { appRoutes } from 'app/app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import LocaleRu from '@angular/common/locales/ru';
import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { MainComponent } from './main/main.component';
flatpickr.localize(Russian);

registerLocaleData(LocaleRu, 'ru');

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    BrowserModule,
    SharedModule,
    CoreModule
  ],
  declarations: [
    AppComponent,
    MainComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru-RU' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
