import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { TableService, TableConfigType } from '@app/shared/services/table.service';
import { Router, ActivatedRoute } from '@angular/router';
import { of, forkJoin, empty, Observable, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NotificationsService } from '@app/core/services/notifications.service';
import { MatDialog } from '@angular/material';
import { FormDialogComponent } from '../form-dialog/form-dialog.component';
import { FetcherService, Response } from '@app/core/services/fetcher.service';
import { NewMenuService, SlugRights } from '@app/core/services/menu.service';
import { TableSearchComponent } from '../table-search/table-search.component';
import { ConfigService } from '@app/core/services/config.service';

interface FetchParams {
  limit: number,
  offset: number,
  isRenovation?: boolean,
  restricted?: boolean,
  dynamicFilter?: string,
  sortType: boolean,
  sortBy: string
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
  @Input() slug: string;
  @Input() systemName: string;

  @ViewChild(TableSearchComponent) search: TableSearchComponent;
  @ViewChild(DatatableComponent) tableComponent: DatatableComponent;

  redirectSlug: string;
  slugRights: SlugRights;

  config: TableConfigType;
  restricted: boolean;
  renovation: boolean;

  isLoadingResults: boolean;
  isLoadingSheets: boolean;
  sheetsAccess: Observable<boolean>;
  disableUI: boolean;
  data = [];
  totalCount: number;

  displayedColumns: string[] = [];
  selected = [];
  tableParams: FetchParams = {
    limit: 25,
    offset: 0,
    sortType: true,
    sortBy: 'name'
  }

  pendedFetching: Subscription;

  readonly pageSizeOptions = [10, 25, 50];
  readonly columnMinMaxWidthByType = {
    string: [150],
    text: [300],
    int: [100],
    float: [100],
    boolean: [40, 350],
    color: [100],
    date: [100],
    file: [150],
    select: [250],
    action: [150, 250]
  }

  constructor(
    private $router: Router,
    private $route: ActivatedRoute,
    private $fetcher: FetcherService,
    private $table: TableService,
    private $notifications: NotificationsService,
    private $menu: NewMenuService,
    private $config: ConfigService,
    public dialog: MatDialog,
    private elementRef: ElementRef<HTMLElement>
  ) {
    this.isLoadingResults = true;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      !this.config ||
      (changes.slug && changes.slug.currentValue !== changes.slug.previousValue) ||
      (changes.systemName && changes.systemName.currentValue !== changes.systemName.previousValue)
    ) {
      if (this.search) { this.search.clear(); }
      this.tableParams = {
        limit: 25,
        offset: 0,
        sortType: true,
        sortBy: 'name'
      };
      this.tableParams.dynamicFilter = null;
      this.sheetsAccess = this.$menu.getIsGS(this.slug);
      this.slugRights = this.$menu.getRights(this.slug, this.systemName);
      this.isLoadingResults = true;
      this.data = [];
      this.redirectSlug = null;
      this.$table.getConfig(this.slug)
        .pipe(
          catchError((error) => {
            this.config = null;
            this.$router.navigate(['undefined'], { relativeTo: this.$route.parent });
            return empty();
          })
        )
        .subscribe((config) => {
          if (config) {
            this.config = config;
            try {
              if (config.redirectSlugName) { this.redirectSlug = config.redirectSlugName; }
              this.displayedColumns = ['select', ...this.config.columns.map(x => x.prop)];
              this.tableParams.sortBy = this.config.sortBy || this.displayedColumns[1];
              this.tableParams.limit = this.config.pageSize || 25;
              this.fetch();
            } catch (error) {
              console.log(error);
              this.$router.navigate(['bad-config'], { relativeTo: this.$route.parent });
            }
            if (this.$config.config.production && this.$config.config.GAID) {
              (<any>window).ga('send', {
                hitType: 'pageview',
                page: this.redirectSlug || this.slug,
                title: config.title,
                location: '/' + this.systemName + '/' + (this.redirectSlug || this.slug)
              });
            }
          } else {
            this.config = null;
            this.$router.navigate(['undefined'], { relativeTo: this.$route.parent });
          }
        });
      const tableHeader = this.elementRef.nativeElement.querySelector<HTMLElement>('.datatable-header .datatable-row-center');
      if (tableHeader) { tableHeader.style.transform = 'translate3d(0px, 0px, 0px)'; }
    }
  }

  onSort({ sorts }) {
    const col = this.config.columns[this.displayedColumns.indexOf(sorts[0].prop) - 1];
    this.tableParams.sortBy = col.type === 'select' ? col.typeProps.linkedKey || col.prop : col.prop;
    this.tableParams.sortType = sorts[0].dir === 'asc';
    this.tableParams.offset = 0;
    this.fetch();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  unselectAll() {
    this.selected = [];
  }

  onPageChange(pageInfo) {
    if (pageInfo.count) {
      this.tableParams.offset = pageInfo.pageSize * pageInfo.offset;
      this.fetch();
    }
  }

  onSearch(event) {
    this.tableParams.dynamicFilter = event;
    this.tableParams.offset = 0;
    this.fetch();
  }

  paramsChanged() {
    this.fetch();
  }

  openGooglesheets() {
    this.isLoadingSheets = true;
    const params: any = this.config.isRestricted ? { restricted: this.tableParams.restricted } : {};
    if (this.config.isRenovation != null) {
      params.isRenovation = this.renovation;
    }
    params.limit = 25000;
    this.$fetcher.get<Response<string>>(`/backoffice/v2/${this.redirectSlug || this.slug}googlesheets`, params)
      .subscribe((response) => {
        if (response) {
          window.open(response.data, '_blank');
          this.isLoadingSheets = false;
        }
      });
  }

  deleteRows() {
    this.isLoadingResults = true;
    this.disableUI = true;
    const observables = this.selected
      .map(s => this.$fetcher.deleteBySlug(this.redirectSlug || this.slug, s.id, this.config.apiPrefix, this.systemName));

    forkJoin(observables).pipe(
      catchError((res) => {
        this.isLoadingResults = false;
        if (res.error) {
          this.$notifications.showError(
            res.error.status || res.error.Status,
            'Не удалось получить данные. ' + (res.error.error || res.error.Error),
            5000
          );
        } else {
          this.$notifications.showError(
            res.Status || res.status,
            'Не удалось получить данные. ' + (res.Error || res.error),
            5000
          );
        }
        return of(null);
      })
    ).subscribe((res) => {
      if (res) {
        this.onRowDelete();
        this.fetch();
      }
    });
  }

  openDialog(edit: boolean, copy: boolean) {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      autoFocus: false,
      maxWidth: null,
      data: {
        config: this.config,
        rows: edit ? this.selected : [],
        slug: this.redirectSlug || this.slug,
        systemName: this.systemName,
        PUTForChange: this.config.PUTForChange,
        copy
      },
      panelClass: 'form-dialog'
    });

    dialogRef.afterClosed().subscribe(changed => {
      if (changed) {
        this.onDialogClose();
        this.fetch();
      }
    });
  }

  private fetch() {
    this.isLoadingResults = true;
    if (this.pendedFetching) {
      this.pendedFetching.unsubscribe();
    }
    this.pendedFetching = this.$fetcher.getBySlug(
      this.redirectSlug || this.slug,
      this.tableParams,
      this.config.apiPrefix,
      this.systemName
    ).pipe(
      map((res: Response<any[]>) => {
        this.isLoadingResults = false;
        this.totalCount = res.meta ? res.meta.totalCount : res.data.length;
        return res.data;
      }),
      catchError((res) => {
        this.isLoadingResults = false;
        if (res.error) {
          this.$notifications.showError(
            res.error.status || res.error.Status,
            'Не удалось получить данные. ' + (res.error.error || res.error.Error),
            5000
          );
        } else {
          this.$notifications.showError(
            res.Status || res.status,
            'Не удалось получить данные. ' + res.Error || res.error,
            5000
          );
        }
        return of([]);
      })
    ).subscribe(data => {
      this.data = data;
      this.unselectAll();
    });
  }

  protected onDialogClose() {}
  protected onRowDelete() {}
}
