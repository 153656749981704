import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NewMenuService, RootTreeItem } from '@app/core/services/menu.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  menu$: Observable<RootTreeItem[]>;
  isFixed = false;
  loading = false;
  selectedSection: number;

  constructor(
    private $menu: NewMenuService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.menu$ = this.$menu.getTree().pipe(
      finalize(() => {
        this.loading = false;
      })
    );
  }
}
