import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface TableConfigType {
  redirectSlugName?: string;
  title: string;
  isRestricted?: boolean;
  isRenovation?: boolean;
  multipleEditing: boolean;
  keyField?: string;
  postAsArray?: boolean;
  PUTForChange?: boolean;
  create?: boolean;
  edit?: boolean;
  delete?: boolean;
  isLongTask?: boolean;
  pageSize?: 10 | 25 | 50;
  sortBy?: string;
  columns: TableColumnType[];
  oneFieldDefault?: string;
  apiPrefix?: string;
  systemName?: string;
  files?: TableFileFieldType[],
  icons?: any;
  showOnCreate?: any;
}

export interface TableColumnType {
  prop: string;
  type: 'string' | 'text' | 'int' | 'float' | 'boolean' | 'color' | 'date' | 'file' | 'action' | 'select';
  label: string;
  width?: number;
  minWidth?: number;
  required?: boolean;
  tableEditing?: boolean;
  multipleEditing?: boolean;
  disabled?: boolean;
  disabledUntil?: string; // Выключить поле, пока не заполнено другое
  hidden?: boolean;
  hiddenUnless?: string;
  sortDisabled?: boolean;
  sticky?: boolean;
  default?: any; // Дефолтное значение при создании записи
  tooltip?: boolean;
  sidePanel?: any;
  typeProps?: any;
}

export interface TableFileFieldType {
  label: string;
  prop: string;
  endPoint: string;
  fileKey: string;
  key: string;
  preview: boolean;
  system: string;
  apiPrefix: string;
}

const defaultOnlyNameConf = (config): TableConfigType => {
 return {
   title: config.oneFieldDefault,
   systemName: config.systemName,
   apiPrefix: config.apiPrefix,
   multipleEditing: false,
   create: true,
   edit: true,
   delete: true,
   isRestricted: false,
   isRenovation: false,
   sortBy: 'name',
   columns: [
     {
       label: 'Название',
       prop: 'name',
       type: 'string',
       tableEditing: true,
       required: true
     }
   ]
 }
};

@Injectable()
export class TableService {
  configs = new Map<string, TableConfigType>();

  constructor(private http: HttpClient) {}

  getConfig(slug: string): Observable<TableConfigType> {
    if (this.configs.has(slug)) { return of(this.configs.get(slug)); }
    const noCacheHeaders = new HttpHeaders({ 'Cache-Control': 'no-cache' })
    return this.http.get<TableConfigType>(`./assets/mock/${slug}.json`, { headers: noCacheHeaders }).pipe(
      map(config => config.oneFieldDefault ? defaultOnlyNameConf(config) : config),
      tap((config) => { this.configs.set(slug, config); })
    );
  }
}
