import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numeral' })
export class NumeralPipe implements PipeTransform {
  transform(input?: string): string | null {
    if (!input || isNaN(Number(input))) {
      return input;
    }
    return String(input).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}
