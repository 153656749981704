import {
  Component, OnInit, HostBinding, Input, SimpleChanges, OnChanges
} from '@angular/core';
import { TableFileFieldType } from '@app/shared/services/table.service';
import { FetcherService } from '@app/core/services/fetcher.service';
import { catchError, finalize } from 'rxjs/operators';
import { empty } from 'rxjs';
import { NotificationsService } from '@app/core/services/notifications.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges {
  @HostBinding('class.file-upload') mainClass = true;

  @Input() value: string;
  @Input() rowId: number;
  @Input() preview = false;
  @Input() systemName = 'bop';
  @Input() config: TableFileFieldType;
  @Input() fileDropAccess: boolean;

  uploading: boolean;
  fileLink: string;

  constructor(
    private $fetcher: FetcherService,
    private $notifications: NotificationsService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.updateFileLink();
    }
  }

  onFileAdd(fileInput: HTMLInputElement) {
    if (fileInput.files.length > 0) {
      this.uploadFile(fileInput.files[0]);
    }
  }

  onFileDrop(ev: any) {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.dataTransfer && ev.dataTransfer.files.length > 0) {
      this.uploadFile(ev.dataTransfer.files[0]);
    }
  }

  private uploadFile(file: Blob) {
    this.uploading = true;
    const fd = new FormData();
    fd.append(this.config.fileKey || 'file', file);
    let url = this.config.endPoint.replace(':id', this.rowId.toString());
    if (this.config.key) {
      url = url.replace(':key', this.config.key);
    }
    this.$fetcher.postBySlug(url, fd, this.config.apiPrefix, this.config.system || this.systemName).pipe(
      catchError((res) => {
        if (res.error) {
          this.$notifications.showError(res.error.Status || res.error.status, res.error.Error || res.error.error);
        } else {
          this.$notifications.showError(res.status, 'Произошла ошибка при загрузке');
        }
        return empty();
      }),
      finalize(() => {
        this.uploading = false;
      })
    ).subscribe(({ data, status }: any) => {
      if (status === 200) {
        this.value = data[this.config.prop];
        this.updateFileLink();
      }
    });
  }

  updateFileLink() {
    if (this.value) {
      if (this.value.includes('backoffice/v1.0/files/ContractDocument')) {
        this.$fetcher.getLink(this.value).subscribe(({ data, status }: any) => {
          if (status === 200) {
            this.fileLink = data;
          }
        });
      } else {
        this.fileLink = this.value;
      }
    }
  }

  remove() { }
}






