import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appCalendarInput]',
})
export class CalendarInputDirective {

  // tslint:disable-next-line: max-line-length
  // private validateRegExp = new RegExp(/^(?:(?:(?:(3)\.?([01]2))\.?(\d{1,4})?)|(?:(3)\.?(1)\.?([469]\d?))|([1-2][0-9]|0?[1-9])\.?(?:(1[0-2]|0?[1-9])\.?(\d{1,4})?|(0)?)|(?:(31)\.?(?:(?:(0?[13578]|1[02])\.?(\d{1,4})?)|(0)?))|(?:(30)\.?(?:(?:(0?[13456789]|1[0-2])\.?(\d{1,4})?)|(0)?))|([0-9]))$/);
  private inputRegExp = new RegExp(/[\d\.-]/);

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter'].indexOf(e.code) !== -1 ||
      (e.code === 'KeyA' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.code === 'KeyC' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.code === 'KeyV' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.code === 'KeyX' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.code === 'KeyA' && e.metaKey === true) || // Cmd+A (Mac)
      (e.code === 'KeyC' && e.metaKey === true) || // Cmd+C (Mac)
      (e.code === 'KeyV' && e.metaKey === true) || // Cmd+V (Mac)
      (e.code === 'KeyX' && e.metaKey === true) || // Cmd+X (Mac)
      e.code === 'ArrowRight' ||
      e.code === 'ArrowLeft' ||
      e.code === 'Home' ||
      e.code === 'End'
    ) {
      return;
    }
    if (!this.inputRegExp.test(e.key)) {
      e.preventDefault();
    }
    // if (!this.validateRegExp.test((e.target as any).value + e.key)) {
    //   e.preventDefault();
    // }
  }
}
