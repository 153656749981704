import { Component, Input, OnInit, Output, EventEmitter, OnChanges, ViewChild, OnDestroy } from '@angular/core';
import { TableColumnType } from '@app/shared/services/table.service';
import { catchError, finalize } from 'rxjs/operators';
import { of, empty, Subscription } from 'rxjs';
import { NotificationsService } from '@app/core/services/notifications.service';
import * as moment from 'moment';
import { FetcherService, ErrorResponse, Response } from '@app/core/services/fetcher.service';

@Component({
  selector: 'app-table-field',
  templateUrl: './table-field.component.html',
  styleUrls: ['./table-field.component.scss']
})
export class TableFieldComponent implements OnInit, OnChanges, OnDestroy {
  @Input() columnConfig: TableColumnType;
  @Input() row: any;
  @Input() value: any;
  @Input() slug: string;
  @Input() editRights: boolean;
  @Input() PUTForChange = false;
  @Output() change = new EventEmitter();
  @Input() apiPrefix = '/backoffice/v2';
  @Input() systemName = 'bop';

  @ViewChild('calendarInput') calendarEl: any;

  _value: any;
  _formattedValue: any;
  selectFilterParams: any;

  calendarObj: any;
  calendarOpen: boolean;
  dateFormat = 'YYYY-MM-DD';
  visibleFormat = 'DD.MM.YY';

  editing: boolean;
  isLoading: boolean;
  activeSubscription: Subscription;

  get editable() {
    return this.editRights &&
      !this.columnConfig.disabled &&
      this.columnConfig.tableEditing &&
      (!this.columnConfig.disabledUntil || this.row[this.columnConfig.disabledUntil]);
  }

  get formattedValue() {
    return this._formattedValue;
  }

  set formattedValue(v) {
    if (v) {
      this._formattedValue = moment(v, this.dateFormat).format(this.visibleFormat);
    } else {
      this._formattedValue = null;
    }
  }

  get valid(): boolean {
    let valid = true;
    if (this.columnConfig.typeProps) {
      const props = this.columnConfig.typeProps;
      valid = valid && (props.regExp ? RegExp(props.regExp, 'i').test(this._value) : true);
    }
    valid = valid && (
      !this.columnConfig.required || (
        this.columnConfig.type === 'string' ?
          this._value.trim().length > 0 :
          this._value !== null && typeof (this._value) !== 'undefined'
      )
    );
    return valid;
  }

  constructor(
    private $fetcher: FetcherService,
    private $notifications: NotificationsService
  ) { }

  ngOnInit() {
    if (this.columnConfig.type === 'date') {
      this.formattedValue = this.value;
    }
    if (this.columnConfig.type === 'select' && this.columnConfig.typeProps.filter) {
      this.selectFilterParams = this.generateSelectFilterParams();
    }
    this.onValueUpdates();
  }

  ngOnChanges() {
    this.onValueUpdates();
  }

  ngOnDestroy() {
    if (this.activeSubscription) {
      this.activeSubscription.unsubscribe();
    }
  }

  onBlur() {
    this.editing = false;
    if (this._value !== this.value && this.valid) {
      this.put(this._value);
    } else {
      this._value = this.value;
    }
  }

  onDateBlur() {
    this.onBlur();
    this.formattedValue = this._value;
  }

  valueClick(value?) {
    if (this.editable) {
      if (typeof (value) !== 'undefined') {
        this._value = value;
        this.onBlur();
      } else {
        this.editing = true;
      }
    }
  }

  updateSelect() {
    this.editing = false;
    if (this.columnConfig.typeProps.multiple) {
      const idKey = this.columnConfig.typeProps.linkedIdKey || 'id';
      if (this._value.map(x => x.id).sort().join() !== this.value.map(x => x[idKey]).sort().join()) {
        this.put(this._value.map(x => x.id));
      }
    } else if (typeof (this._value) !== 'undefined' && this._value && this._value.id !== this.value) {
      this.put(this._value.id);
    } else {
      this.onValueUpdates()
    }
  }

  removeItem(index: number) {
    if (this.editable) {
      this._value.splice(index, 1);
      this.put(this._value.map(x => x.id));
    }
  }

  action() {
    this.isLoading = true;
    if (this.activeSubscription) {
      this.activeSubscription.unsubscribe();
    }
    const props = this.columnConfig.typeProps;
    const data = props.data || {};
    if (props.fields && props.fields.length > 0) {
      props.fields.forEach(field => {
        data[field] = this.row[field];
      });
    }
    const url = (props.apiPrefix || this.apiPrefix) + (props.key ? props.endPoint.replace(':id', this.row[props.key]) : props.endPoint);
    let activeObservable;
    if (['post', 'put'].includes(props.method)) {
      activeObservable = this.$fetcher[this.columnConfig.typeProps.method](url, data, props.systemName || this.systemName);
    } else {
      activeObservable = this.$fetcher[this.columnConfig.typeProps.method](url, props.systemName || this.systemName);
    }

    this.activeSubscription = activeObservable.pipe(
      catchError((res) => {
        if (res.error) {
          this.$notifications.showError(res.error.status || res.error.Status, res.error.error || res.error.Error);
        } else {
          this.$notifications.showError(res.status, res.message);
        }
        return empty();
      }),
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(() => {
      this.$notifications.showMessage('Обновлено', 'done', 1500);
    });
  }

  private put(value) {
    this.isLoading = true;
    const _value = this.columnConfig.type === 'int' || this.columnConfig.type === 'float' ? value * 1 : value;
    const params = { [this.columnConfig.prop]: _value };
    const typeProps = this.columnConfig.typeProps;
    if (typeProps && typeProps.clearOnChange) {
      typeProps.clearOnChange.forEach((key) => {
        params[key] = null;
      });
    }
    this.activeSubscription = this.$fetcher.patchBySlug<any>({
      slug: this.slug,
      id: this.row.id,
      data: params,
      apiPrefix: this.apiPrefix,
      systemName: this.systemName,
      isPut: this.PUTForChange
    }).pipe(
      catchError((res: ErrorResponse) => {
        if (res.error) {
          this.$notifications.showError(res.error.status || res.error.Status, res.error.error || res.error.Error);
        } else {
          this.$notifications.showError(res.status, res.message);
        }
        return empty();
      }),
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe((res: Response<any>) => {
      if (res) {
        this.row[this.columnConfig.prop] = this.value = res.data[this.columnConfig.prop];
        if (
          this.columnConfig.type === 'select' &&
          !typeProps.multiple
        ) {
          // Обновление полей
          if (typeProps.updateOnChange) {
            typeProps.updateOnChange.forEach((fields: string[]) => {
              this.row[fields[0]] = res.data[fields[0]];
            });
          }
          if (typeProps.clearOnChange) {
            typeProps.clearOnChange.forEach((key) => {
              this.row[key] = null;
            });
          }
          const nameKey = typeProps.linkedKey;
          this.row[nameKey] = res.data[nameKey];
        }
        this.onValueUpdates();
      }
    });
  }

  private onValueUpdates() {
    if (this.columnConfig.type === 'select') {
      const idKey = this.columnConfig.typeProps.linkedIdKey || 'id';
      const nameKey = this.columnConfig.typeProps.linkedKey || 'name';
      if (this.columnConfig.typeProps.multiple) {
        this._value = this.value.map(x => ({
          id: x[idKey],
          name: x[nameKey]
        }));
      } else {
        this._value = {
          id: this.value,
          [this.columnConfig.typeProps.key]: this.row[nameKey]
        }
      }
    } else if (this.columnConfig.type === 'date') {
      this._value = this.value;
      this.formattedValue = this.value;
    } else {
      this._value = this.value;
    }
    if (this.columnConfig.type === 'select' && this.columnConfig.typeProps.filter) {
      this.selectFilterParams = this.generateSelectFilterParams();
    }
  }

  private generateSelectFilterParams() {
    const filter = this.columnConfig.typeProps.filter;
    if (!filter) { return null; }
    if (typeof (filter) === 'object') {
      return filter;
    } else {
      if (this.columnConfig.typeProps.search) {
        return {
          linkTable: filter.replace('Id', ''),
          linkTableId: this.row[filter]
        }
      } else {
        return {
          [filter]: this.row[filter]
        };
      }
    }
  }
}
