import {
  Component, Input,  Output, Attribute,
  forwardRef, ChangeDetectorRef,
  HostBinding, HostListener,
  ViewChild, OnInit,
  ElementRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export enum KEY_CODE {
  ENTER = 13,
}

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorInputComponent),
      multi: true
    }
  ]
})
export class ColorInputComponent implements ControlValueAccessor, OnInit {
  @HostBinding('class.app-input-inline') get inline() {
    return !this.placeholder;
  }
  @HostBinding('class.app-input') mainClass = true;

  get hasValue() {
    return this._value && this._value.length > 0 || this.value;
  }

  public get value() {
    return this._value;
  }

  public set value(v) {
    this._value = v;
    this.onChange(this._value);
    this.onTouched();
  }
  private _value: string;
  focused = false;
  focusOnInit = false;

  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() clearable = true;
  @Input() type = 'text';

  @ViewChild('inputEl') input: ElementRef;
  @Output() blur = new EventEmitter;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ENTER) {
      this.input.nativeElement.blur();
    }
  }

  constructor(
    @Attribute('class') public classes: string,
    @Attribute('tabindex') public tabIndex: string,
    @Attribute('autofocus') private autoFocus: any,
    private _cd: ChangeDetectorRef
  ) {
    this.focusOnInit = this.autoFocus;
  }

  ngOnInit() {
    if (this.inline) {
      this.input.nativeElement.focus();
    }
  }

  writeValue(value: string): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._cd.markForCheck();
  }

  onChange: any = () => { };

  onTouched: any = () => { };

  onInputFocus() {
    this.focused = true;
  }

  onInputBlur() {
    this.focused = false;
    this.blur.emit();
  }

  clear() {
    this.value = '';
    this._cd.markForCheck();
  }
}
