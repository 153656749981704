import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `
    <div class="error">
      <div class="container error__container">
        <span class="error__number">404</span>
        <p class="error__message">
          Данной страницы не существует. Вы можете попробовать <a class="error__link" routerLink="/">перейти на главную страницу</a>
        </p>
      </div>
    </div>
  `,
  styleUrls: ['./404.component.scss']
})
export class NotFoundComponent {}
