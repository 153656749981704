import { Component, OnInit, OnDestroy } from '@angular/core';

import { PikAuthService } from 'pik-product-header';
import { NgSelectConfig } from '@ng-select/ng-select';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { NewMenuService } from './core/services/menu.service';
import { ConfigService } from './core/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  access: Observable<boolean>;
  isAuthorized: boolean;
  version: string;
  userLogin: string;

  sidePanelOpen: boolean;
  currentLocation: string;

  constructor(
    public $auth: PikAuthService,
    private selectConfig: NgSelectConfig,
    private $config: ConfigService,
    private $menu: NewMenuService
    // private $notifications: NotificationsService,
  ) {
    this.$auth.authCallbackIfRequired();
    this.selectConfig.notFoundText = 'Ничего не найдено';
    this.selectConfig.loadingText = 'Загрузка...';
    this.selectConfig.typeToSearchText = 'Начните печатать для поиска';
    this.currentLocation = location.host;
    this.version = environment.version;
  }

  ngOnInit() {
    // const snack = this.$notifications.showLoader('Завершаем авторизацию');
    if (this.$config.config.production && this.$config.config.GAID) {
      (<any>window).ga('create', this.$config.config.GAID, 'auto');
    }
    this.$auth.isAuthorized().subscribe(isAuthorized => {
      this.isAuthorized = isAuthorized;
      if (isAuthorized) {
        this.access = this.$menu.hasAccess();
        this.$auth.getUserData().pipe(
          map((user) => user ? user.email : 'Неавторизованный юзер')
        ).subscribe((login: string) => {
          if (this.userLogin !== login) {
            this.userLogin = login;
            if (this.$config.config.production && this.$config.config.GAID) {
              (<any>window).ga('set', 'userId', login);
            }
          }
        });
      } else {
        console.log('Неавторизованный пользователь. Перенаправление...');
      }
    });
  }

  ngOnDestroy(): void {
    this.$auth.unsubscribeModuleSetup();
  }
}
