import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { environment } from '../../environments/environment';

import { AuthModule } from 'pik-angular-auth-oidc-client';
import {
  PikHeaderModule,
  PIK_AUTH_SETTINGS,
  PikAuthService,
  PikAuthSettings,
  PikAuthModule,
  PORTAL_URL,
  PORTAL_API_URL,
  MDS_API_URL,
  HEADER_VISUAL_SETTING
} from 'pik-product-header';

import { ErrorPageComponent } from './error-page/error-page.component';
import { MenuComponent } from './menu/menu.component';
import { ConfigService } from './services/config.service';
import { FetcherService } from './services/fetcher.service';
import { NewMenuService } from './services/menu.service';
import { UserService } from './services/user.service';
import { NotificationsService } from './services/notifications.service';

import { MenuResolver } from './resolvers/menu.resolver';

import { ApplyTokenInterceptor } from './interceptors/apply-token.interceptor';
import { CamelCaseInterceptor } from './interceptors/camel-case.interceptor';


export function initAuthFactory(configService: ConfigService) {
  configService.config = (window as any).ENV_CONFIG;
  const { auth, needAuth } = configService.config || environment;
  const authSettings: PikAuthSettings = {
    client_id: auth.client_id,
    scope: auth.scope,
    stsUrl: auth.stsUrl,
    allowExternal: true,
    showHeaderForExternal: true,
    needAuth,
  };
  return authSettings;
}

export function initVisualSettingsFactory(configService: ConfigService) {
  configService.config = (window as any).ENV_CONFIG;
  const env = configService.config || environment;
  return env['headerVisualSettings'] || {};
}

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    BrowserAnimationsModule,
    PikHeaderModule.forRoot(),
    AuthModule.forRoot(),
    PikAuthModule.forRoot()
  ],
  exports: [
    BrowserAnimationsModule,
    PikHeaderModule,
    MenuComponent,
    ErrorPageComponent
  ],
  declarations: [
    MenuComponent,
    ErrorPageComponent
  ],
  providers: [
    ConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplyTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CamelCaseInterceptor,
      multi: true
    },
    {
      provide: PIK_AUTH_SETTINGS,
      useFactory: initAuthFactory,
      deps: [ConfigService],
      multi: false
    },
    PikAuthService,
    FetcherService,
    NewMenuService,
    NotificationsService,
    UserService,
    { provide: PORTAL_URL, useValue: environment.homeUrl },
    { provide: PORTAL_API_URL, useValue: environment.homeApiUrl },
    { provide: MDS_API_URL, useValue: environment.homeApiUrl },
    {
      provide: HEADER_VISUAL_SETTING,
      useFactory: initVisualSettingsFactory,
      deps: [ConfigService],
      multi: false
    },
    MenuResolver
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
