import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PikAuthService } from 'pik-product-header';
import { map } from 'rxjs/operators';
import { ConfigService } from './config.service';

export interface Response<T> {
  status: number;
  meta: {
    totalCount: number;
    limit: number;
    offset: number;
    userExists: boolean;
  };
  data: T;
  error: string;
}

export interface ErrorResponse {
  error: {
    data;
    error: string;
    meta;
    status: number;
    Status?: number;
    Error?: string;
  };
  message: string;
  name: string;
  ok: boolean;
  status: number;
  statusText: string;
  statusUrl: string;
}
interface PatchOptions { slug: string; id: number; data: any; apiPrefix: string; systemName: string; isPut?: boolean };

@Injectable()
export class FetcherService {
  constructor(
    protected http: HttpClient,
    protected authService: PikAuthService,
    private configSerivce: ConfigService
  ) { }

  private buildHeaders(): HttpHeaders {
    // HttpHeaders - immutable поэтому .set идёт сразу при объявлении
    let headers = new HttpHeaders();

    headers = headers.set('Authorization', this.authService.getAuthorizationHeaderValue());

    return headers;
  }

  getApiUrl(url: string, serviceName: string): string {
    return `${this.configSerivce.config.api}${serviceName.length ? '/' + serviceName : ''}${url}`;
  }

  /**
   * Выполняет get-запрос
   *
   * @template T
   * @param {string} url
   * @param {HttpParams} [paramsObject=new HttpParams]
   * @returns {Observable<Response<string>>}
   * @memberof Fetcher
   */
  getLink(url: string, paramsObject: any = new HttpParams): Observable<Response<string>> {
    let params = new HttpParams;
    const headers = this.buildHeaders();

    for (const key of Object.keys(paramsObject)) {
      if (paramsObject[key] !== null) {
        params = params.append(key, paramsObject[key]);
      }
    }

    const options = {
      headers,
      params,
      withCredentials: true,
    };

    return this.http.get<Response<string>>(`${url}`, options);
  }

  getBySlug(slug: string, paramsObject: any = {}, apiPrefix: string, systemName = 'bop'): Observable<Response<any[]>> {
    let params = new HttpParams();
    for (const key of Object.keys(paramsObject)) {
      if (paramsObject[key] != null) {
        params = params.append(key, paramsObject[key]);
      }
    }
    return this.http.get<Response<any[]>>(this.getApiUrl(`${apiPrefix}/${slug}`, systemName), { params });
  }

  patchBySlug<T>({ slug, id, data, apiPrefix, systemName = 'bop', isPut }: PatchOptions): Observable<Response<T>> {
    const headers = isPut ? {} : new HttpHeaders({ 'Content-Type': 'application/merge-patch+json' });

    if (slug === 'businessroleuser' && !isPut) {
      systemName = ''
    }

    return this.http[isPut ? 'put' : 'patch']<Response<T>>(this.getApiUrl(`${apiPrefix}/${slug}/${id}`, systemName), data, { headers });
  }

  autoCompleteBySlug<T>(slug: string, params, apiPrefix: string, systemName = 'bop'): Observable<T> {
    return this.http.get<Response<T>>(this.getApiUrl(`${apiPrefix}/filter/${slug}`, systemName), { params })
      .pipe(map((resolve) => resolve.data));
  }

  postBySlug<T>(slug: string, data, apiPrefix: string, systemName = 'bop'): Observable<Response<T>> {
    return this.http.post<Response<T>>(this.getApiUrl(`${apiPrefix}/${slug}`, systemName), data);
  }

  deleteBySlug(slug: string, id: number, apiPrefix: string, systemName = 'bop'): Observable<any> {
    return this.http.delete(this.getApiUrl(`${apiPrefix}/${slug}/${id}`, systemName));
  }

  // Старый код фетчера:

  get<T>(url: string, paramsObject: any = {}, serviceName = 'bop') {
    let params = new HttpParams();
    for (const key of Object.keys(paramsObject)) {
      if (paramsObject[key] != null) {
        params = params.append(key, paramsObject[key]);
      }
    }
    return this.http.get<T>(this.getApiUrl(url, serviceName), { params });
  }

  post<T>(url: string, data, serviceName = 'bop') {
    return this.http.post<T>(this.getApiUrl(url, serviceName), data);
  }

  put<T>(url: string, data, serviceName = 'bop') {
    const headers = new HttpHeaders({ 'Content-Type': 'application/merge-patch+json' });
    return this.http.patch<T>(this.getApiUrl(url, serviceName), data, { headers });
  }

  delete<T>(url: string, serviceName = 'bop') {
    return this.http.delete<T>(this.getApiUrl(url, serviceName));
  }
}
