import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss']
})
export class TableSearchComponent implements OnChanges {

  @Input() disableUI = true;
  @Output() onSearch: EventEmitter<any> = new EventEmitter;

  searchControl: FormControl = new FormControl;
  searchValues: string[] = []

  ngOnChanges(disableUI: SimpleChanges): void {
    if (this.disableUI === true) {
      this.searchControl.disable()
    } else {
      this.searchControl.enable()
    }
  }

  addChip(event): void {
    const value = event.target.value;

    if (value) {
      this.searchValues.push(value);
      this.onSearch.emit(this.searchValues);
      this.searchControl.reset();
    }

  }

  removeChip(value): void {
    const index = this.searchValues.indexOf(value);

    if (index >= 0) {
      this.searchValues.splice(index, 1)
      this.onSearch.emit(this.searchValues);
    }
  }

  clear(): void {
    this.searchValues = [];
  }
}
