import { Component, Inject, OnInit, HostListener } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TableConfigType, TableColumnType } from '@app/shared/services/table.service';
import { NotificationsService } from '@app/core/services/notifications.service';
import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Observable, of, concat, forkJoin, empty } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FetcherService } from '@app/core/services/fetcher.service';

@Component({
  selector: 'app-form-dialog',
  templateUrl: 'form-dialog.component.html',
  styleUrls: ['form-dialog.component.scss']
})
export class FormDialogComponent implements OnInit {
  _row: any;
  form: FormGroup;
  isDuplicate: boolean;
  visibleFields: TableColumnType[];

  dragging = 0;
  fileDropAccess: boolean;
  @HostListener('dragenter', ['$event'])
  fileDragenter = function (ev: any): void {
    ev.preventDefault();
    ev.stopPropagation();
    this.dragging++;
    ev.dataTransfer.effectAllowed = 'copy';
    this.fileDropAccess = true;
  };

  @HostListener('dragleave', ['$event'])
  fileDragleave = function (ev: any): void {
    ev.preventDefault();
    ev.stopPropagation();
    this.dragging--;
    if (this.dragging === 0) {
      this.fileDropAccess = false;
    }
  };

  @HostListener('dragover', ['$event'])
  preventDrop = function (ev: any) {
    ev.preventDefault();
  };

  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { config: TableConfigType, slug: string, rows?: any, systemName?: string, copy?: boolean },
    private $notifications: NotificationsService,
    private $fetcher: FetcherService
  ) { }

  ngOnInit() {
    this.visibleFields = [...this.data.config.columns.filter(x => !x.hidden)];
    if (this.data.rows && this.data.rows.length > 0 && this.data.rows.length < 2) {
      this._row = Object.assign({}, this.data.rows[0]);
    } else {
      const initialValues = {};
      this.visibleFields.forEach(col => {
        if (typeof (col.default) !== 'undefined') {
          initialValues[col.prop] = col.default;
        }
      });
      this._row = Object.assign({}, initialValues);
    }

    // Создаю форму
    const formObject: any = {};
    this.visibleFields.forEach((col) => {
      if (!col.hidden) {
        formObject[col.prop] = this.getFormControl(col);
      }
    });
    this.form = new FormGroup(formObject);

    // Подписываюсь на изменения
    this.form.valueChanges.subscribe(ev => this.onChanges(ev));

    // Создаю подписки для полей с фильтрами
    this.visibleFields.forEach((col) => {
      if (col.type === 'select') {
        if (col.typeProps.filter) {
          col.typeProps.filter$ = this.getSelectFilterParams(col);
        }
        if (col.typeProps.clearOnChange || col.typeProps.putOnChange) {
          this.form.get(col.prop).valueChanges.subscribe((value) => {
            const toClear = col.typeProps.clearOnChange;
            if (toClear) {
              toClear.forEach(key => {
                if (this.form.get(key)) { this.form.get(key).setValue(null); }
              });
            }

            const toChange = col.typeProps.putOnChange;
            if (toChange) {
              Object.keys(toChange).forEach(key => {
                if (this.form.get(key) && this.form.get(key).value !== value) {
                  this.form.get(key).setValue(value ? value[toChange[key]] : null);
                }
              });
            }
          });
        }
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  dismiss() {
    this.dialogRef.close();
  }

  onSelectChange(column: TableColumnType, value: any) {
    if (value && column.typeProps.updateOnChange) {
      column.typeProps.updateOnChange.forEach((fields: any) => {
        const control = this.form.get(fields[0]);
        if (control) {
          if (typeof (fields[1]) === 'object') {
            const _value = {
              id: value[fields[1].id],
              name: value[fields[1].name]
            };
            control.setValue(_value);
          } else {
            control.setValue(value[fields[1]]);
          }
        }
      });
    }
  }

  onChanges(value) {
    this.visibleFields.forEach((col) => {
      if (col.disabledUntil) {
        if (this.form.get(col.disabledUntil).value) {
          this.form.get(col.prop).enable({ emitEvent: false });
        } else {
          this.form.get(col.prop).disable({ emitEvent: false });
        }
      }
    });
  }

  setValue(prop: string, value: any) {
    this.form.patchValue({ [prop]: value }, { emitEvent: true })
  }

  save(manualPatch = false) {
    const config = this.data.config;
    let request: Observable<any>;
    if (!manualPatch && (this.data.rows.length === 0 || this.data.copy)) {
      request = this.$fetcher.postBySlug(
        this.data.slug,
        this.data.config.postAsArray === false ? this.getDirtyValues(false) : [this.getDirtyValues(false)],
        config.apiPrefix,
        config.systemName || this.data.systemName
      ).pipe(
        map((res) => {
          if (res.data && config.showOnCreate) {
            let message = 'Создана запись. ';
            config.showOnCreate.fields.forEach((item: string[]) => {
              if (res.data[item[1]]) {
                message += `${item[0]}: "${res.data[item[1]]}" \n`;
              }
            });
            this.$notifications.showMessage(message, 'done', config.showOnCreate.delay);
          }
          return res;
        })
      );
    } else {
      request = forkJoin(this.data.rows.map((row) => {
        return this.$fetcher.patchBySlug({
          slug: this.data.slug,
          id: row.id,
          data: this.getDirtyValues(),
          apiPrefix: config.apiPrefix,
          systemName: config.systemName || this.data.systemName,
          isPut: config.PUTForChange
        });
      }));
    }
    request
      .pipe(
        catchError((res) => {
          if (res.Status === 409) {
            this.isDuplicate = true;
            return empty();
          } else {
            this.$notifications.showError(res.error.Status || res.error.status, res.error.Error || res.error.error);
            return of(null);
          }
        }),
      )
      .subscribe((res) => {
        this.dialogRef.close(!!res);
      });
  }

  getSelectFilterParams(column): Observable<any> {
    const filter = column.typeProps.filter;
    if (typeof (filter) !== 'object') {
      return concat(
        of(this.getSelectFilter(column)),
        this.form.get(filter).valueChanges.pipe(
          map(filterValue => this.getSelectFilter(column, filterValue))
        )
      );
    } else { return of(filter); }
  }

  private getSelectFilter(column, value?) {
    const filter = column.typeProps.filter;
    const filterValue = value || this.form.get(filter).value;
    if (column.typeProps.search) {
      return {
        linkTable: filter.replace('Id', ''),
        linkTableId: filterValue ? filterValue.id : null,
      };
    } else {
      return {
        [filter]: filterValue ? filterValue.id : null
      };
    }
  }

  private getFormControl(col): FormControl {
    const disabled =
      col.disabled ||
      (col.disabledUntil && !this._row[col.disabledUntil]) ||
      (col.disabledOn && this._row[col.disabledOn]) ||
      (this.data.rows.length > 1 && !col.multipleEditing);
    const value = { value: this.getRawColValue(col), disabled };
    const validators: ValidatorFn[] = [];
    if (col.required) { validators.push(Validators.required); }
    if (col.typeProps) {
      if (col.typeProps.regExp) { validators.push(Validators.pattern(new RegExp(col.typeProps.regExp))); }
      if (col.typeProps.min) { validators.push(Validators.min(col.typeProps.min)); }
      if (col.typeProps.max) { validators.push(Validators.max(col.typeProps.max)); }
    }
    return new FormControl(value, validators);
  }

  private getRawColValue(col: TableColumnType) {
    let value = this._row[col.prop];
    if (col.type === 'select' && value) {
      const idKey = col.typeProps.linkedIdKey || 'id';
      const nameKey = col.typeProps.linkedKey || 'name';
      if (!col.typeProps.multiple) {
        value = {
          id: value,
          [col.typeProps.key]: this._row[nameKey]
        };
      } else {
        value = value.map(x => ({
          id: x[idKey],
          name: x[nameKey]
        }));
      }
    }
    return value;
  }

  private getDirtyValues(checkForDirty = true) {
    const dirtyValues = {};
    this.visibleFields.forEach(column => {
      const currentControl = this.form.get(column.prop);

      if (currentControl.value && (!checkForDirty || currentControl.dirty)) {
        if (column.type === 'select') {
          if (column.typeProps.multiple) {
            dirtyValues[column.prop] = currentControl.value.map(x => x.id);
          } else {
            dirtyValues[column.prop] = currentControl.value.id;
          }
        } else {
          const _value = column.type === 'int' || column.type === 'float' ? currentControl.value * 1 : currentControl.value;
          dirtyValues[column.prop] = _value;
        }
      }
    });
    return dirtyValues;
  }

}
