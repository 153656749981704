import { Injectable } from '@angular/core';
import { element } from 'protractor';

@Injectable()
export class ScrollToService {

  constructor() { }

  scrollToElement(el: HTMLElement, duration: number = 0) {
    this.animation(el.offsetTop, duration);
  }

  scrollTo(position: number, duration: number = 0) {
    this.animation(position, duration);
  }

  private animation(position: number, duration: number) {
    let then = Date.now();

    const difference = position - document.documentElement.scrollTop;
    let animationTime = 0;

    const callback = () => {
      const delta = Date.now() - then;
      then = Date.now();
      if (animationTime < duration) {
        animationTime += delta;
        const move = difference / duration * delta;

        window.scrollTo(0, window.scrollY + move);

        // document.body.scrollTop += move;
        if (window.scrollY < position) {
          window.scrollTo(0, position);
          // document.body.scrollTop = position;
        }
        requestAnimationFrame(callback);
      } else {
        if (document.body) {
          window.scrollTo(0, position);
        }
      }
    };

    requestAnimationFrame(callback);
  }

}
