import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';

import { ScrollToService } from 'app/shared/services/scroll-top.service';

import { TableSearchComponent } from 'app/shared/components/table-search/table-search.component';

import { NumeralPipe } from './pipes/numeral.pipe';

import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  RippleGlobalOptions,
  MatSnackBarModule
} from '@angular/material';
import { TableComponent } from './components/table/table.component';
import { TableFieldComponent } from './components/table/table-field.component';
import { InputComponent } from './components/input/input.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { UndefinedTableComponent } from './components/undefined-table/undefined-table.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { ColorInputComponent } from './components/color-input/color-input.component';
import { NotificationComponent } from './components/notification/notification.component';
import { TableService } from './services/table.service';
import { NotFoundComponent } from './components/404/404.component';
import { CalendarInputDirective } from './directives/calendar-input.directive';
import { InputFilterDirective } from './directives/input-filter.directive';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ColorPickerModule,
    NgxDatatableModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatChipsModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule
  ],
  exports: [
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TableComponent,
    TableFieldComponent,
    InputComponent,
    DateInputComponent,
    CustomSelectComponent,
    UndefinedTableComponent,
    FileUploadComponent,
    FormDialogComponent,
    NotFoundComponent,
    ColorInputComponent,
    NotificationComponent,
    NgxDatatableModule,
    TableSearchComponent,

    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatChipsModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,

    NumeralPipe
  ],
  declarations: [
    TableSearchComponent,
    TableComponent,
    TableFieldComponent,
    InputComponent,
    DateInputComponent,
    CustomSelectComponent,
    UndefinedTableComponent,
    FileUploadComponent,
    FormDialogComponent,
    NotFoundComponent,
    ColorInputComponent,
    NotificationComponent,
    CalendarInputDirective,
    InputFilterDirective,

    NumeralPipe
  ],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    ScrollToService,
    TableService
  ],
  entryComponents: [FormDialogComponent, NotificationComponent]
})
export class SharedModule { }
