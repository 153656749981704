import {
  Component, Input, Output,
  EventEmitter, forwardRef, Attribute,
  ChangeDetectorRef, ViewChild, ElementRef, HostBinding, HostListener, OnInit, AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import flatpickr from 'flatpickr';
import * as moment from 'moment';
import { Instance } from 'flatpickr/dist/types/instance';

const COMMON_FORMATS = ['D', 'D.M', 'D.M.YY', 'D.M.YYYY', 'DMYY', 'DMYYYY', 'YYYY-MM-DD'];
enum KEY_CODE {
  ENTER = 13,
}

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    }
  ]
})
export class DateInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @HostBinding('class.app-input--inline') get inline() {
    return !this.placeholder;
  }
  @HostBinding('class.app-input--has-value') get hasValue() {
    return (this._value && this._value.length > 0) || this._formattedValue;
  };

  public get value(): string {
    return this._value;
  }

  public set value(v) {
    const date = moment(v, this.dateInFormats, true);
    if (v) {
      if (date.isValid()) {
        this._value = v;
        this.formattedValue = date.format(this.visibleFormat);
      } else {
        this._value = null;
        this.formattedValue = 'Неверный формат';
      }
    } else {
      this._value = null;
      this.formattedValue = '';
    }
    if (this.calendar && this.calendar.isOpen) {
      this.calendar.setDate(this._value ? date.toDate() : null);
    }
    this.onChange(this._value);
    this.onTouched();
  }

  get formattedValue() {
    return this._formattedValue;
  }

  set formattedValue(v) {
    this._formattedValue = v;
  }
  @HostBinding('class.app-input') mainClass = true;
  @HostBinding('class.app-input--focused') focused = false;
  @HostBinding('class.app-input--disabled') disabled = false;

  calendar: Instance;

  @Input() placeholder: string;
  @Input() openOnFocus = false;
  @Input() dateInFormats = [
    'YYYY-MM-DD',
    moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
    moment.HTML5_FMT.DATETIME_LOCAL_MS,
    'YYYY-MM-DDTHH:mm:ss.SSSSSS',
    'YYYY-MM-DDTHH:mm:ss.SS'
  ];
  @Input() dateOutFormat = 'YYYY-MM-DD';
  @Input() visibleFormat = 'DD.MM.YY';
  @Input() clearable = true;
  @Input() required = false;
  @Output() change = new EventEmitter();
  @Output() blur = new EventEmitter;
  @ViewChild('inputEl') input: ElementRef;
  @ViewChild('dateInput') dateInput: ElementRef;

  private _value: string;
  private _formattedValue: string;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ENTER) {
      if (this.input) { this.input.nativeElement.blur(); }
    }
  }

  constructor(
    @Attribute('class') public classes: string,
    @Attribute('tabindex') public tabIndex: string,
    @Attribute('autofocus') private autoFocus: any,
    private _cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.focused = this.inline || this.autoFocus !== null;
  }

  ngAfterViewInit() {
    if (this.inline || this.autoFocus !== null) {
      if (this.input) { this.input.nativeElement.focus(); }
    }
  }

  enableFlatpickr(event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!this.disabled) {
      this.calendar = flatpickr(this.dateInput.nativeElement, {
        dateFormat: 'd.m.y',
        static: true,
        clickOpens: false,
        onOpen: (selectedDates: any[], dateStr: string, instance: any) => {
          if (this._value) {
            instance.setDate(moment(this._value, this.dateInFormats, true).toDate());
          }
        },
        onChange: (selectedDates, dateStr, instance) => {
          this.value = moment(dateStr, [this.visibleFormat, ...COMMON_FORMATS], true).format(this.dateOutFormat);
        },
        onClose: (selectedDates, dateStr, instance) => {
          setTimeout(() => {
            instance.destroy();
            instance.element.blur();
            this.onInputBlur();
          }, 200);
        }
      });
      this.calendar.open();
      this.focused = true;
    }
  }

  writeValue(v: string): void {
    this.value = v;
    // this.formattedValue = v;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._cd.markForCheck();
  }

  onChange: any = (v) => { };

  onTouched: any = () => { };

  onInputFocus() {
    this.focused = true;
    if (this.openOnFocus) {
      this.enableFlatpickr();
    }
  }

  onInputBlur() {
    this.focused = false;
    this.blur.emit();
  }

  onInputChange(event) {
    this.value = moment(event.target.value, [this.visibleFormat, ...COMMON_FORMATS], true).format(this.dateOutFormat);
  }

  clear(event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.value = null;
    this._cd.markForCheck();
    this.blur.emit();
  }

  public validate(c: FormControl) {
    return null;
  }
}
