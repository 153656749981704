import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

interface AppConfig {
  production: boolean;
  api: string;
  needAuth: boolean;
  auth: {
    client_id: string;
    scope: string;
    stsUrl: string;
    allowExternal: boolean;
  };
  GAID: string;
}

@Injectable()
export class ConfigService {
  private _config: AppConfig;

  get config(): AppConfig {
    return this._config;
  }

  set config(value) {
    this._config = value ? value : environment;
  }
}
