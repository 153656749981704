
// import {publishReplay} from 'rxjs/operators/publishReplay';
import { map, share } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { FetcherService, Response } from './fetcher.service';

export interface User {
  adm_RoleId: number;
  admin: boolean;
  email: string;
  fullName: string;
  globalId: number;
  id: number;
  isNotDeleted: boolean;
  isRenovation: boolean;
  isRestricted: boolean;
  linkToUserCard: string;
  login: string;
  mds_EmployeeId: number;
  owner: boolean;
  photoUrl: string;
  reports: any[];
  rights: any[];
  userExists: boolean;
  userName: string;
}

@Injectable()
export class UserService {
  current: User;
  activeCurrentRequest: Observable<User>;

  constructor(private $fetcher: FetcherService) { }

  getCurrent(): Observable<User> {
    if (this.current) {
      return of(this.current);
    }
    if (this.activeCurrentRequest) {
      return this.activeCurrentRequest;
    }
    return this.activeCurrentRequest = this.$fetcher.get<Response<User>>('/api/v2/user').pipe(
      map(response => response.data),
      share()
    );
  }
}
