import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function reqListener() {
  if (this.status === 200) {
    (window as any).ENV_CONFIG = JSON.parse(this.responseText);
  } else {
    reqError();
  }

  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
}

function reqError(err?) {
  (window as any).ENV_CONFIG = environment;
}

const ConfigRequest = new XMLHttpRequest();
ConfigRequest.onload = reqListener;
ConfigRequest.onerror = reqError;
ConfigRequest.open('get', '/config/', true);
ConfigRequest.send();
